#customersTab tbody {
	border-top: 1px solid #ccc;

	&.children {
		border-left: 2px solid $primary;
		border-bottom: 1px solid #ccc;
		td:first-child:before {
			content: " - ";
		}
	}
}