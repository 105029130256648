$dimensions: 24 64;

.image {
  display: block;
  position: relative;
  img {
    display: block;
    height: auto;
    width: 100%; }
  // Ratio
  &.is-2by1 {
    img {
      @include overlay;
      height: 100%;
      width: 100%; } }
  &.is-2by1 {
    padding-top: 50%; }
  // Sizes
  @each $dimension in $dimensions {
    &.is-#{$dimension}x#{$dimension} {
      height: $dimension * 1px;
      width: $dimension * 1px; } } }
