@-webkit-keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.86);
            transform: scale(0.86);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.86);
            transform: scale(0.86);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.anim-fadeIn {
	animation: fadeIn 200ms both;
}

.anim-slideUp {
	animation: slideUp 200ms both;
}

@for $i from 1 through 5 {
  .anim-delay-#{$i} {
    animation-delay: #{$i*0.2}s
  }
}