.has-float-label {
  display: block;
  position: relative;
}
.has-float-label label, .has-float-label > span {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}
.has-float-label select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.has-float-label input, .has-float-label select {
  font-size: inherit;
  padding-top: 1em;
  margin-bottom: 2px;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.has-float-label input::-webkit-input-placeholder, .has-float-label select::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}
.has-float-label input:placeholder-shown:not(:focus)::-webkit-input-placeholder, .has-float-label select:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}
.has-float-label input:placeholder-shown:not(:focus) + *, .has-float-label select:placeholder-shown:not(:focus) + * {
  font-size: 150%;
  opacity: .5;
  top: .25em;
}
.has-float-label input:focus, .has-float-label select:focus {
  outline: none;
  border-color: rgba(0, 0, 0, 0.5);
}
.has-float-label select {
  padding-right: 1em;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.5em bottom 0.25em;
  background-size: 8px 10px;
}

.has-float-label {
	input, select {
	  width: 100%;
	  margin-bottom: .5rem;
	}
}

.has-float-label {
	input.is-danger {
		border-color: red;
	}
 }