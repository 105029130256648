.block {
  @include block; }

.container {
  position: relative;
  @include desktop {
    margin: 0 auto;
    max-width: $desktop - 40px; // 960px
    // Modifiers
    &.is-fluid {
      margin: 0 20px;
      max-width: none; } }
  @include widescreen {
    max-width: $widescreen - 40px; } } // 1152px

.delete {
  @include delete; }

.fa {
  font-size: 21px;
  text-align: center;
  vertical-align: top; }

.heading {
  display: block;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase; }
