$title:             $grey-darker !default;
$title-size:        $size-3 !default;
$title-weight:      $weight-light !default;
$title-weight-bold: $weight-semibold !default;

$subtitle:          $grey-dark !default;
$subtitle-size:     $size-5 !default;
$subtitle-strong:   $grey-darker !default;
$subtitle-weight:   $weight-light !default;

.title,
.subtitle {
  @include block;
  word-break: break-word; }

.title {
  color: $title;
  font-size: $title-size;
  font-weight: $title-weight;
  line-height: 1.125;
  & + .subtitle {
    margin-top: -1.25rem; }
 }  // Colors

.subtitle {
  color: $subtitle;
  font-size: $subtitle-size;
  font-weight: $subtitle-weight;
  line-height: 1.25;
  & + .title {
    margin-top: -1.5rem; }
 }  // Colors
