$control-radius: $radius !default;
$control-radius-small: $radius-small !default;

@mixin control {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: none;
  border-radius: $control-radius;
  box-shadow: none;
  display: inline-flex;
  font-size: $size-normal;
  height: 2.285em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  position: relative;
  vertical-align: top;
  // States
  &:focus,
  &.is-focused,
  &:active,
  &.is-active {
    outline: none; }
  &[disabled],
  &.is-disabled {
    pointer-events: none; } }

// The controls sizes use mixins so they can be used at different breakpoints
@mixin control-small {
  border-radius: $control-radius-small;
  font-size: $size-small; }
@mixin control-medium {
  font-size: $size-medium; }
@mixin control-large {
  font-size: $size-large; }
