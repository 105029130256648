aside, .menu-link {
	display: none;
}

[type=radio]:checked+.checkable:after, [type=checkbox]:checked+.checkable:after {
    content: '⏺';
    color: #000;
}

.box {
	border: 1px solid #dbdbdb;
}
