////////////////////////////////////////////////
////////////////////////////////////////////////
// 1. Initial variables

// Colors
$black:        hsl(0, 0%, 4%) !default;
$black-bis:    hsl(0, 0%, 7%) !default;
$black-ter:    hsl(0, 0%, 14%) !default;

$grey-darker:  hsl(0, 0%, 21%) !default;
$grey-dark:    hsl(0, 0%, 29%) !default;
$grey:         hsl(0, 0%, 38%) !default;
$grey-light:   hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;

$white-ter:    #f7f5f1 !default;//hsl(0, 0%, 96%) !default
$white-bis:    hsl(0, 0%, 98%) !default;
$white:        hsl(0, 0%, 100%) !default;

$orange:       #F1D2CB !default;
$yellow:       #FFD01A !default;
$green:        #AAC8A8 !default;
$turquoise:    #B6DEE9 !default;
$blue:         #A4BECD !default;
$purple:       #B6DEE9 !default;
$red:          #EC694A !default;
$brown:         #ECBB8D !default;

$krav:         #00674C !default;
$krav-bg:         #6DA069 !default;

// Typography
$family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$family-monospace: "Inconsolata", "Consolas", "Monaco", monospace !default;

$size-1: 3.5rem !default;
$size-2: 2.75rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 14px !default;
$size-7: 0.75rem !default;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-semibold: 500 !default;
$weight-bold: 700 !default;

// Miscellaneous
$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 3px !default;
$radius-large: 5px !default;
$speed: 86ms !default;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 2. Primary colors

$primary: $krav !default;

$info: $blue !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 3. Applied variables

// Invert colors
$orange-invert: findColorInvert($orange) !default;
$yellow-invert: findColorInvert($yellow) !default;
$green-invert: findColorInvert($green) !default;
$turquoise-invert: findColorInvert($turquoise) !default;
$blue-invert: findColorInvert($blue) !default;
$purple-invert: findColorInvert($purple) !default;
$red-invert: findColorInvert($red) !default;

$primary-invert: findColorInvert($krav) !default;
$info-invert: $blue-invert !default;
$success-invert: $green-invert !default;
$warning-invert: $yellow-invert !default;
$danger-invert: $red-invert !default;
$light-invert: $dark !default;
$dark-invert: $light !default;

// General colors
$background: $white-ter !default;

$border: $grey-lighter !default;
$border-hover: $grey-light !default;

// Text colors
$text: $grey-dark !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $grey-darker !default;

// Code colors
$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors
$link: $primary !default;
$link-invert: $primary-invert !default;
$link-visited: $primary !default;

$link-hover: $grey-darker !default;
$link-hover-border: $grey-light !default;

$link-focus: $grey-darker !default;
$link-focus-border: $primary !default;

$link-active: $grey-darker !default;
$link-active-border: $grey-dark !default;

// Typography
$family-primary: $family-sans-serif !default;
$family-code: $family-monospace !default;

$size-small: $size-7 !default;
$size-normal: 1rem !default;
$size-medium: $size-5 !default;
$size-large: $size-4 !default;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 4. Lists and maps

$colors: (white: ($white, $black), primary: ($primary, $primary-invert), info: ($info, $info-invert), success: ($success, $success-invert), warning: ($warning, $warning-invert), danger: ($danger, $danger-invert)) !default;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 !default;
