.tipsy { padding: 5px; font-size: 14px; position: absolute; z-index: 100000; }
  .tipsy-inner { padding: 5px 8px 4px 8px; background-color: black; color: white; max-width: 200px; text-align: center; }
  .tipsy-inner { border-radius: 3px; -moz-border-radius:3px; -webkit-border-radius:3px; }
  .tipsy-arrow { position: absolute; width: 0;
                                       height: 0;
                                       border-left: 9px solid transparent;
                                       border-right: 9px solid transparent;

                                       border-bottom: 9px solid black; }
  .tipsy-n .tipsy-arrow { top: 0; left: 50%; margin-left: -4px; }
