
.fa-search:before { content: '\e800'; } /* '' */
.fa-users:before { content: '\e801'; } /* '' */
.fa-calendar:before { content: '\e802'; } /* '' */
.fa-ok:before { content: '\e803'; } /* '' */
.fa-thumbs-up:before { content: '\e804'; } /* '' */
.fa-mail:before { content: '\e805'; } /* '' */
.fa-lock:before { content: '\e806'; } /* '' */
.fa-attention:before { content: '\e807'; } /* '' */
.fa-lock-open:before { content: '\e808'; } /* '' */
.fa-home:before { content: '\e809'; } /* '' */
.fa-fish:before { content: '\e80a'; } /* '' */
.fa-info-circled:before { content: '\e80b'; } /* '' */
.fa-money:before { content: '\e80c'; } /* '' */
.fa-mushroom:before { content: '\e80d'; } /* '' */
.fa-th-list:before { content: '\e80e'; } /* '' */
.fa-arrows-cw:before { content: '\e80f'; } /* '' */
.fa-boj:before { content: '\e810'; } /* '' */
.fa-truck:before { content: '\e811'; } /* '' */
.fa-leaf:before { content: '\e812'; } /* '' */
.fa-camera:before { content: '\e813'; } /* '' */
.fa-adjust:before { content: '\e814'; } /* '' */
.fa-wrench:before { content: '\e815'; } /* '' */
.fa-floppy:before { content: '\e816'; } /* '' */
.fa-attach:before { content: '\e817'; } /* '' */
.fa-cancel:before { content: '\e818'; } /* '' */
.fa-user:before { content: '\e819'; } /* '' */
.fa-phone:before { content: '\e81a'; } /* '' */
.fa-plus:before { content: '\e81b'; } /* '' */
.fa-thumbs-down:before { content: '\e81c'; } /* '' */
.fa-edit:before { content: '\e81d'; } /* '' */
.fa-minus-circled:before { content: '\e81e'; } /* '' */
.fa-ok-circled2:before { content: '\e81f'; } /* '' */
.fa-tags:before { content: '\e820'; } /* '' */
.fa-tint:before { content: '\e821'; } /* '' */
.fa-basket:before { content: '\e822'; } /* '' */
.fa-upload:before { content: '\e823'; } /* '' */
.fa-plus-circled:before { content: '\e824'; } /* '' */
.fa-resize-small:before { content: '\e825'; } /* '' */
.fa-resize-full:before { content: '\e826'; } /* '' */
.fa-pencil:before { content: '\e827'; } /* '' */
.fa-th-large:before { content: '\e828'; } /* '' */
.fa-link-ext:before { content: '\f08e'; } /* '' */
.fa-menu:before { content: '\f0c9'; } /* '' */
.fa-magic:before { content: '\f0d0'; } /* '' */
.fa-sitemap:before { content: '\f0e8'; } /* '' */
.fa-food:before { content: '\f0f5'; } /* '' */
.fa-doc-text:before { content: '\f0f6'; } /* '' */
.fa-building:before { content: '\f0f7'; } /* '' */
.fa-angle-right:before { content: '\f105'; } /* '' */
.fa-angle-up:before { content: '\f106'; } /* '' */
.fa-angle-down:before { content: '\f107'; } /* '' */
.fa-github:before { content: '\f113'; } /* '' */
.fa-unlink:before { content: '\f127'; } /* '' */
.fa-level-up:before { content: '\f148'; } /* '' */
.fa-thumbs-up-alt:before { content: '\f164'; } /* '' */
.fa-sun:before { content: '\f185'; } /* '' */
.fa-bug:before { content: '\f188'; } /* '' */
.fa-paw:before { content: '\f1b0'; } /* '' */
.fa-tree:before { content: '\f1bb'; } /* '' */
.fa-file-excel:before { content: '\f1c3'; } /* '' */
.fa-forumbee:before { content: '\f211'; } /* '' */
.fa-ship:before { content: '\f21a'; } /* '' */
.fa-calendar-check-o:before { content: '\f274'; } /* '' */
.fa-percent:before { content: '\f295'; } /* '' */
.fa-handshake-o:before { content: '\f2b5'; } /* '' */
.fa-address-book-o:before { content: '\f2ba'; } /* '' */
.fa-address-card-o:before { content: '\f2bc'; } /* '' */
