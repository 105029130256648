/* A font by Jos Buivenga (exljbris) -> www.exljbris.com */
@font-face{
	font-family:"Museo 300";

	src: url("/assets/font/MuseoCompleteSet/Museo-300.woff2") format('woff2'),
         url("/assets/font/MuseoCompleteSet/Museo-300.woff") format('woff'),
         url("/assets/font/MuseoCompleteSet/Museo-300.otf") format("truetype");

	font-display: swap;
}
@font-face{
	font-family:"Museo 700";

	src: url("/assets/font/MuseoCompleteSet/Museo-700.woff2") format('woff2'),
        url("/assets/font/MuseoCompleteSet/Museo-700.woff") format('woff'),
        url("/assets/font/MuseoCompleteSet/Museo-700.otf") format("truetype");

	font-display: swap;
}
