/*
Add transition to containers so they can push in and out.
*/
$menu-width: 250px;

.drawerBackdrop {
    z-index: 20;
    background-color: #000;
    transition: opacity .2s cubic-bezier(0,0,0.3,1);
    height: 0;
    opacity: 0;
    will-change: opacity;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}

.drawerBackdrop.active {
   opacity: .5;
   height: auto;
    display: block;
}

.menu {
	transition: transform 0.2s cubic-bezier(0,0,0.3,1);
    -webkit-overflow-scrolling: touch;
}

/*
The `.menu` `<div>` is the parent `<div>` that contains the `.pure-menu` that
appears on the left side of the page.
*/

.menu.active {
    transform: none;
    pointer-events: auto;
    overflow-x: hidden;
    overflow-y: auto;
}

/* -- Responsive Styles (Media Queries) ------------------------------------- */

/*
Hides the menu at `48em`, but modify this based on your app's needs.
*/
@media (min-width: $desktop) {

    #main {
        padding-left: $menu-width;
    }

    .header,
    .content {
        padding-left: 2em;
        padding-right: 2em;
    }

    .menu-link {
        display: none;
    }

    .drawerBackdrop.active {
        height: 0;
    }
}

.submenu .transparent {
    line-height: 1em;
}

.menu-label {
	padding-left: 1em;
}

#menu .hero {
    padding: 1em;
    .title {
        color: #000;
    }
}

.menu-link {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    background: #000;
    background: rgba(0,0,0,0.7);
    z-index: 10;
    height: auto;
    text-align: center;
    color: #fff;
    font-size: 1.7em;
    width: 1.7em;
    line-height: 1.7em;
    font-weight: bold;
}

.fa-link-ext {
	font-size: 1em;
}