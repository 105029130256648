.cookie-notice-parent {
	color: rgb(255, 255, 255);
	background-color: rgb(0, 104, 72);
	position: fixed;
	min-width: 100%;
	height: auto;
	z-index: 100000;
	left: 0;
	bottom: 0;
	text-align: center;
}
