// Radio button

// Variables
$picnic-radio-size: 1em !default;
$picnic-radio-border: 1px solid #aaa !default;

// 
%radio {
  opacity: 0;
  width: 0;
  position: absolute;
  display: inline-block;
  
  + .checkable:hover:before,
  &:focus + .checkable:before {
    border: 1px solid $picnic-primary;
    }
  
  + .checkable {
    position: relative;
    cursor: pointer;
    padding-left: $picnic-radio-size * 1.5;
    margin-right: .6em;
        
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: $picnic-radio-size;
      line-height: $picnic-radio-size;
      color: transparent;
      font-family: sans;
      text-align: center;
      box-sizing: border-box;
      width: $picnic-radio-size;
      height: $picnic-radio-size;
      border-radius: 50%;
      transition: $picnic-transition;
      }

    &:before {            
      border: $picnic-radio-border;
      }
    }

  &:checked + .checkable:after {
    background: #555;
    transform: scale(.5) translateY(-100%);
    }
  }
